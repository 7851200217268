import React, { useRef, useEffect } from 'react';
import initializeMapKitWithToken from './mapkitConfig';

const Map = ({ mapToken, longitude, latitude }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null); 
  const mapInitialized = useRef(false);
  const isRegionSet = useRef(false);

  useEffect(() => {
    if (!mapInitialized.current) {
      initializeMapKitWithToken(mapToken);

      const map = new window.mapkit.Map(mapRef.current);
      mapInstanceRef.current = map; 
      map.colorScheme = window.mapkit.Map.ColorSchemes.Adaptive;
      map.showsPointsOfInterest = false;

      mapInitialized.current = true;
    }
  }, [mapToken]);

  useEffect(() => {
    const map = mapInstanceRef.current; 
    if (map) {
      const runnerCoordinate = new window.mapkit.Coordinate(latitude, longitude);

      const marker = new window.mapkit.MarkerAnnotation(runnerCoordinate, {
        color: '#A36258',
        glyphText: "🏃",
        title: "Aktueller Standort",
      });

      map.removeAnnotations(map.annotations); 
      map.addAnnotation(marker); 

      if (!isRegionSet.current) { 
        const region = new window.mapkit.CoordinateRegion(
          runnerCoordinate,
          new window.mapkit.CoordinateSpan(0.01, 0.01)
        );
        map.setRegionAnimated(region, true);
        isRegionSet.current = true; 
      };
    }
  }, [longitude, latitude]);

  return <div ref={mapRef} style={{ height: '100vh', width: '100%' }} />;
};

export default Map;