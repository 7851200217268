import React from 'react';
import './RunNotFound.css';

const RunNotFound = ({ isLoading, isFinished, notFound }) => {

const openWebsite = () => {
    window.location.href = 'https://followmy.run';
    };

return (
    <div className="not-found-container">
        {isLoading ? (
            <p className="not-found-text">Wird geladen...</p>
        ) : isFinished ? (
            <div>
                <div className="search-icon">🏃</div>
                <p className="not-found-text">Der Lauf wurde bereits beendet.</p>
            </div>
        ) : notFound ? (
            <div>
                <div className="search-icon">🔍</div>
                <p className="not-found-text">Der Lauf konnte nicht gefunden werden.</p>
            </div>
        ) : null}

        <footer className="footer">
            <div className="footer-info" onClick={openWebsite}>
            <img src="/Icon.webp" alt="Maps Icon" className="footer-icon"/>
                <span className="footer-name">FollowMyRun</span>
            </div>
            <a href="https://followmy.run/impressum">Impressum</a>
        </footer>
    </div>
);
}

export default RunNotFound;
