import React from 'react';
import './Stats.css';

function Stats({ runData, displayTime, pace }) {
  return (
    <div className="container">
      <a href="https://followmy.run" className="impressum-link">Impressum</a>
      <div className="top-section">
        <div className="runtime-label">Laufzeit</div>
        <div className="runtime">{displayTime}</div>
      </div>
      <div className="stats-box">
        <div className="stat">
          <span className="label-distance">Distanz</span>
          <span className="value">{(runData.distance / 1000).toFixed(2)}</span>
        </div>
        <div className="stat">
          <span className="label-pace">Pace</span>
          <span className="value">{pace}</span>
        </div>
        <div className="stat">
          <span className="label-altitude">Höhe</span>
          <span className="value">{runData.altitude}</span>
        </div>
      </div>
    </div>
  );
}

export default Stats;