import React, { useEffect, useState } from "react";
import Map from './Map';
import RunNotFound from './RunNotFound';

import { initializeApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import Stats from './Stats';
import './App.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const specificEndDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));

function App() {
  const [runData, setRunData] = useState([]);
  const [displayTime, setDisplayTime] = useState('');
  const [pace, setPace] = useState('N/A');

  // Get the runID from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const runID = urlParams.get('id');

  // Get the run data from the database
  useEffect(() => {
    if(runID) {
      const docRef = doc(db, "runs", runID); 

      // Subscribe to the run data from firestore
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          setRunData({ id: doc.id, ...doc.data() });
        } else {
          console.log("Das Dokument existiert nicht!");
        }
      });

      return () => unsubscribe();
    }
  }, [runID]);

  useEffect(() => {
    if(runData.startDate) {
      var counter = 5;
      const timer = setInterval(() => {
        if(!runData.isPaused) {
          const now = new Date();
          const start = new Date(runData.startDate.seconds * 1000);
          const elapsed = (now - start) / 1000; // Zeit in Sekunden seit dem Start
          const totalSeconds = runData.duration + elapsed; // Gesamtzeit in Sekunden
          const distanceInKilometers = runData.distance / 1000; // Distanz in Kilometern
          const paceInSecondsPerKilometer = totalSeconds / distanceInKilometers; // Pace in Sekunden pro Kilometer
          const paceMinutes = Math.floor(paceInSecondsPerKilometer / 60); // Minutenanteil der Pace
          const paceSeconds = Math.floor(paceInSecondsPerKilometer % 60); // Sekundenanteil der Pace
          const formattedPace = `${paceMinutes}:${String(paceSeconds).padStart(2, '0')}`; // Formatierung der Pace
    
          counter++;
          if(counter % 7 === 0) {
            setPace(formattedPace);
            counter = 0;
          }
    
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = Math.floor(totalSeconds % 60);
          const formattedTime = [hours, minutes, seconds].map(unit => String(unit).padStart(2, '0')).join(':');
          setDisplayTime(formattedTime);
        }
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [runData]);

  return (
    <div>
      {runData ? (
          runData.startDate && runData.endDate && runData.lastUpdate ? (
              (new Date(runData.endDate.seconds * 1000).getTime() === specificEndDate.getTime()) &&
              (new Date().getTime() - new Date(runData.lastUpdate.seconds * 1000).getTime()) <= 15 * 60 * 1000 ? (
                <div>
                  <Map mapToken={process.env.REACT_APP_MAPTOKEN} longitude={runData.longitude} latitude={runData.latitude} />
                  <Stats runData={runData} displayTime={displayTime} pace={pace}/>
                </div>
              ) : (
              <div>
                <RunNotFound isLoading={false} isFinished={true} notFound={false} />
              </div>
              )
          ) : (
            <div>
              <RunNotFound isLoading={false} isFinished={false} notFound={true} />
            </div>
          )
        ) : (
          <div>
            <RunNotFound isLoading={true} isFinished={false} notFound={false} />
          </div>
        )}
    </div>
  );
}

export default App;
